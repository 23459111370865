<template>
    <span v-if="resultNotifications === true && type === 'warning'" class="notification" >!</span>
    <span v-else-if="resultNotifications !== null && type === 'number'" class="notification">{{ resultNotifications }}</span>
    <div v-else-if="resultNotifications === true && type === 'point'" class="notification" style="width:9px; height:9px; background-color:red;  border : initial"></div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'NotificationStickerComponent',
  data() {
    return {
      dataNotification:[],
      resultNotifications: null, // Initialiser la liste des resultNotifications à vide
      possiblePathLocation:{
        home: {
          path: "home",
          almanach: {
            path: "almanach",
            concerts: { path: "concerts" },
            conference: { path: "conference" },
            sports: { path: "sports" }
          },
          darwin: { path: "darwin" },
          annuaire: {path:"annuaire"},
          metriques:{path:"metriques"}
        },
        search: { path:"search" },
        wall: { path:"wall" },
        profil: { path:"profil" },
        user: { path:"user" },

      }
    };
  },
  props:['location','type'],
  computed:{
    ...mapState(['user','notifications']),
  },
  mounted() {
    this.waitForNotifications();
  },
  methods: {

    async waitForNotifications() {
      while (this.$store.state.notifications.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      this.dataNotification=this.$store.state.notifications
      this.sortNotification(this.location,this.type);
    },

    sortNotification(location, type) {
      // Effectuer la requête GET vers les resultNotifications avec les filtres spécifiés
      const paths = this.findPathLocation(this.getPathData(location))
      for (const i in paths){
        const filteredNotification = this.dataNotification.filter(item => item.attributes.type === paths[i]);
        if (filteredNotification.length>0){
            if (type === 'number') {
              this.resultNotifications += filteredNotification.length;
            } else {
              this.resultNotifications = true;
            }
          }else{
            console.log('Pas de notification valide');
          }
      }
    },
    findPathLocation(obj) {
      let paths = [];
      // Parcourir toutes les clés de l'objet actuel
      for (const key in obj) {
        // Vérifier si la clé est un chemin
        if (key === 'path') {
          // Ajouter la valeur du chemin au tableau
          paths.push(obj[key]);
        } else if (typeof obj[key] === 'object') {
          // Si la clé est un objet, effectuer une recherche récursive dans cet objet
          const childPaths = this.findPathLocation(obj[key]);
          // Concaténer les chemins trouvés avec le tableau principal
          paths = paths.concat(childPaths);
        }
      }

      return paths;
    },

    getPathData(path, obj = this.possiblePathLocation) {
      // Vérifier si la clé spécifiée est présente dans l'objet actuel
      if (path in obj) {
        return obj[path];
      }

      // Parcourir récursivement les enfants de l'objet actuel s'ils existent
      for (const value of Object.values(obj)) {
        if (typeof value === 'object') {
          const result = this.getPathData(path, value);
          if (result !== null) {
            return result;
          }
        }
      }

      // Si aucune valeur avec la clé spécifiée n'est trouvée, retourner null ou une valeur par défaut selon votre cas
      return null;
    },
  }
};
</script>

<style scoped>

.notification{
  display:inline-block;
  width:19px; height:19px;
  padding-top: 2px;
  background-color:black;
  border-radius:calc(19px/2);
  padding-right: 2px;
  padding-left: 2px;
  color: #FFE200;
  text-align: center;
  font-size: 11px;
  position: absolute;
  border : #FFE200 solid 2px;
}
</style>
