import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    token: localStorage.getItem('strapi_jwt') || null,
    bookings: [],
    search: '',
    events: [],
    notifications:[],
    selectedCanal: { "id": 1, "attributes": { "title": "Wall" } }
  },
  getters: {
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_TOKEN(state, token) {
      localStorage.setItem('strapi_jwt', token)
      state.token = token
    },
    updateDeviceToken(state, newToken) {
      state.user.device_token = newToken;
      console.log('state.user :',state.user)
    },
    SET_BOOKINGS(state, bookings) {
      state.bookings = bookings
    },
    SET_SEARCH(state, search) {
      state.search = search
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    SET_SELECTED_CANAL(state, canal) {
      state.selectedCanal = canal
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setBookings({ commit }, bookings) {
      commit('SET_BOOKINGS', bookings)
    },
    setSearch({ commit }, search) {
      commit('SET_SEARCH', search)
    },
    setEvents({ commit }, events) {
      commit('SET_EVENTS', events)
    },
    setNotifications({ commit }, notifications) {
      commit('SET_NOTIFICATIONS', notifications)
    },
    setSelectedCanal({ commit }, canal) {
      commit('SET_SELECTED_CANAL', canal)
    }
  },
  modules: {
  }
})
